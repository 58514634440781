<template>
  <div>
    <b-modal
      :hide-footer="true"
      id="modal-schedule"
      title="Cronograma de entregas"
    >
      <form @submit="handleSubmit">
        <p>Inserte la cantidad de viviendas a entregar por año</p>
        <table border="1" class="table table-hover table-striped">
          <tr>
            <th v-for="index in years" :key="index.id">
              ENTREGA {{ index.year }}
            </th>
            <th>TOTAL</th>
          </tr>
          <tr>
            <td v-for="index in years" :key="index.id">
              <b-form-input
                class="quantity_field"
                :data-year_id="index.id"
                placeholder="0"
                type="number"
              ></b-form-input>
            </td>
            <td><b-form-input id="total_houses" disabled></b-form-input></td>
          </tr>
        </table>
        <div class="col-md-12 my-4 text-end form-group">
          <b-button
            @click="$bvModal.hide('modal-schedule')"
            variant="outline-success"
            class="mx-4 btn-secondary btn text-white"
            ><i class="fa fa-times"> </i> &nbsp; Cancelar</b-button
          >
          <b-button type="submit" variant="primary"
            ><i class="fa fa-save"> </i>&nbsp;Guardar</b-button
          >
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import {
  getDeliveryScheduleYears,
  createDeliverySchedule,
} from "../../../services/TypologyAPI";
import Swal from "sweetalert2";

export default {
  name: "tabs",
  props: {
    typology_id: Number,
    house_quantities: Array,
  },
  data() {
    return {
      years: [],
      quantities_selected: [],
      house_quantities_saved: [],
    };
  },
  mounted() {
    this.getYears();

    // if(this.typology_id !== null){
    //   this.getHouseQuantitiesSaved();
    // }
  },
  methods: {
    getYears() {
      getDeliveryScheduleYears()
        .then((data) => {
          this.years = data.years;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    handleSubmit(e) {
      e.preventDefault();

      const fields = document.getElementsByClassName("quantity_field");
      const count_fields = fields.length;

      for (let i = 0; i < count_fields; i++) {
        let data = {
          year_id: fields[i].getAttribute("data-id"),
          quantity: fields[i].value,
        };
        this.quantities_selected.push(data);
      }

      createDeliverySchedule({
        typology_house_id: this.typology_id,
        quantities: this.quantities_selected,
      })
        .then((data) => {
          Swal.fire({
            title: "¡Proceso exitoso!",
            text: data.message,
            icon: "success",
            confirmButtonText: "Listo",
          });
        })
        .catch(() => {
          Swal.fire({
            title: "¡Ha ocurrido un error!",
            text: "Favor intente de nuevo",
            icon: "error",
            confirmButtonText: "Volver",
          });
        });
    },
  },
};
</script>
