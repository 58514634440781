<template>
  <div>
    <Tabs :activeTab="2" />
    <form id="developerDataForm" v-on:submit="this.handleSubmit">
    <div class="content-wrap-container">
      <div class="row">
        <div class="col-md-12">
          <div class="row mb-3">
            <div class="col-sm form-group">
              <label for="rnc">Nombre del Proyecto</label>
              <input
                type="text"
                class="form-control"
                v-model="project_form.name"
                disabled
              />
            </div>
            <div class="col-sm form-group">
              <label for="business_name">Dirección</label>
              <input
                type="text"
                id="business_name"
                class="form-control"
                v-model="project_form.address"
                disabled
              />
            </div>
            <div class="col-sm form-group">
              <label for="address">Georeferencia</label>
              <input
                type="text"
                class="form-control"
                v-model="project_form.georeference"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row mb-3">
            <div class="col-sm form-group">
              <label for="rnc">Responsable del Proyecto</label>
              <input
                type="text"
                class="form-control"
                v-model="project_form.project_manager"
                disabled
              />
            </div>
            <div class="col-sm form-group">
              <label for="business_name">Cédula de identidad / Pasaporte</label>
              <input
                type="text"
                class="form-control"
                v-model="project_form.identity_card"
                disabled
              />
            </div>
            <div class="col-sm form-group">
              <label for="address">Teléfono</label>
              <input
                type="text"
                class="form-control"
                v-model="project_form.phone_number"
                disabled
              />
            </div>
            <div class="col-sm form-group">
              <label for="state">Celular</label>
              <input
                type="text"
                class="form-control"
                v-model="project_form.cell_phone_number"
                disabled
              />
            </div>
            <div class="col-sm form-group">
              <label for="legal_representation">Corre electrónico</label>
              <input
                type="text"
                class="form-control"
                v-model="project_form.email"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      </div><!--end content-wrap-container-->

      <!----- REGISTRO DE  VIVIENDAS POR TIPOLOGIA ----->
      <div class="content-wrap-container">
        <div class="row">
          <div class="col-md-12 my-4 form-group">
            <h5>
              Registro de Viviendas por Tipología
              <b-button
                v-if="showResume"
                @click="showResume = false"
                variant="primary"
                ><i class="fa fa-plus"> </i> &nbsp;Agregar</b-button
              >
            </h5>
          <div v-if="!showResume">
            <div class="row">
              <div class="col-md-12">
                <div class="row mb-3">
                  <div class="col-sm form-group">
                    <label for="rnc">Tipología</label>
                    <b-form-select
                      class="form-select"
                      v-model="form.typology_id"
                      :options="Typologies"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                  <div class="col-sm form-group">
                    <label for="business_name">Tipo de Vivienda</label>
                    <b-form-select
                      class="form-select"
                      v-model="form.house_type_id"
                      :options="HousingTypes"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                  <div class="col-sm form-group">
                    <label for="address">Cantidad por tipología</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="0"
                      v-model="form.quantity_per_typology"
                    />
                  </div>
                  <div class="col-sm form-group">
                    <label for="state">Sistema Constructivo</label>
                    <b-form-select
                      v-model="form.construction_system_id"
                      class="form-select"
                      :options="ConstructionSystems"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                  <div class="col-sm form-group">
                    <label for="legal_representation"
                      >Sistema estructural (otro)</label
                    >
                    <input
                      type="text"
                      id="legal_representation"
                      class="form-control"
                      v-model="form.other"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row mb-3">
                  <div class="col-sm form-group">
                    <label for="rnc">m2 por vivienda</label>
                    <b-form-input
                      class="form-input"
                      type="number"
                      placeholder="0.00"
                      required
                      v-model="form.housing_m2"
                    ></b-form-input>
                  </div>
                  <div class="col-sm form-group">
                    <label for="business_name">Precio de venta por m2</label>
                    <b-form-input
                      class="form-input"
                      type="number"
                      placeholder="0.00"
                      v-model="form.sale_price_m2"
                      required
                    ></b-form-input>
                  </div>
                  <div class="col-sm form-group">
                    <label for="address">Cantidad de habitaciones</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="form.number_rooms"
                      placeholder="0"
                    />
                  </div>

                  <!-- FECHAS DE ENTREGA -->
                  <div class="col-sm form-group">
                    <b-button
                      v-b-modal.modal-schedule
                      @click="getHousesQuantities"
                      class="mt-4 col-12"
                      v-if="form.id != null"
                    >
                      <i class="fa fa-plus"> </i> &nbsp; Fechas de Entrega
                    </b-button>
                  </div>
                  <div class="col-sm form-group"></div>
                </div>
              </div>
            </div>
            <h5>Terminaciones y acabados</h5>
            <div class="row">
              <div class="col-md-12">
                <div class="row mb-3">
                  <div class="col-sm form-group">
                    <label for="rnc">Pisos</label>
                    <b-form-select
                      v-model="form.floor_id"
                      class="form-select"
                      :options="AllFloors"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                  <div class="col-sm form-group">
                    <label for="business_name">Otros</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.floors_other"
                      required
                    />
                  </div>

                  <div class="col-sm form-group">
                    <label for="rnc">Pintura interior</label>
                    <b-form-select
                      class="form-select"
                      v-model="form.interior_paint_id"
                      :options="InteriorPaints"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                  <div class="col-sm form-group">
                    <label for="business_name">Otros</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.interior_painting_other"
                    />
                  </div>
                  <div class="col-sm form-group">
                    <label for="rnc">Hierros</label>
                    <b-form-select
                      class="form-select"
                      v-model="form.iron_id"
                      :options="Irons"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="row mb-3">
                  <div class="col-sm form-group">
                    <label for="rnc">Puertas</label>
                    <b-form-select
                      class="form-select"
                      v-model="form.door_id"
                      :options="Doors"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                  <div class="col-sm form-group">
                    <label for="business_name">Otros</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.doors_other"
                    />
                  </div>

                  <div class="col-sm form-group">
                    <label for="rnc">Pintura exterior</label>
                    <b-form-select
                      class="form-select"
                      v-model="form.exterior_paint_id"
                      :options="ExteriorPaints"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                  <div class="col-sm form-group">
                    <label for="business_name">Otros</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.exterior_painting_other"
                    />
                  </div>
                  <div class="col-sm form-group">
                    <label for="rnc">Gabinete de arriba (Pared)</label>
                    <b-form-select
                      class="form-select"
                      v-model="form.upstairs_cabinet_id"
                      :options="UpstairsCabinets"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="row mb-3">
                  <div class="col-sm form-group">
                    <label for="rnc">Ventanas</label>
                    <b-form-select
                      class="form-select"
                      v-model="form.window_id"
                      :options="Windows"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                  <div class="col-sm form-group">
                    <label for="business_name">Otros</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.windows_other"
                    />
                  </div>

                  <div class="col-sm form-group">
                    <label for="rnc">Terminación interior</label>
                    <b-form-select
                      class="form-select"
                      v-model="form.interior_ending_id"
                      :options="InteriorEndings"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                  <div class="col-sm form-group">
                    <label for="business_name">Otros</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.inside_finish_other"
                    />
                  </div>
                  <div class="col-sm form-group">
                    <label for="rnc">Gabinete de abajo (Piso)</label>
                    <b-form-select
                      class="form-select"
                      v-model="form.bottom_cabinet_id"
                      :options="BottomCabinets"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row mb-3">
                  <div class="col-sm form-group">
                    <label for="rnc">Cocina</label>
                    <b-form-select
                      class="form-select"
                      v-model="form.kitchen_id"
                      :options="Kitchens"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                  <div class="col-sm form-group">
                    <label for="business_name">Otros</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.kitchen_other"
                    />
                  </div>

                  <div class="col-sm form-group">
                    <label for="rnc">Terminación exterior</label>
                    <b-form-select
                      class="form-select"
                      v-model="form.exterior_ending_id"
                      :options="ExteriorEndings"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
                  <div class="col-sm form-group">
                    <label for="business_name">Otros</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.outside_finish_other"
                    />
                  </div>
                  <div class="col-sm form-group"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 my-4 text-end form-group">
          <b-button
            v-if="!showResume"
            variant="outline-success"
            class="mx-4 btn-secondary btn text-white"
            @click="hideEdit"
            >Cerrar</b-button
          >
          <b-button variant="primary" type="submit" v-if="!showResume"
            ><i class="fa fa-save"> </i> &nbsp;Guardar</b-button
          >
        </div>
      </div>

      <!-- RESUME TYPOLOGIES -->
      <div class="row" v-if="showResume">
        <div class="col-md-12 text-start mb-3">
          <h5>Resumen por Tipo de vivienda</h5>
        </div>
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th>Tipología</th>
                <th>Tipo de Vivienda</th>
                <th>Cantidad</th>
                <th>Habitaciones</th>
                <th>M2 Habitable</th>
                <th>Precio de Venta</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="data in housing_typologies_created"
                v-bind:key="data.id"
              >
                <td>{{ data.typology.name }}</td>
                <td>{{ data.house_type.name }}</td>
                <td>{{ data.quantity_per_typology }}</td>
                <td>{{ data.number_rooms }}</td>
                <td>{{ data.housing_m2 }}</td>
                <td>{{ data.sale_price_m2 }}</td>
                <td>
                  <i
                    @click="getHouseTypologyById(data.id)"
                    class="pointer fa fa-pencil"
                  ></i>
                  &nbsp;
                  <i class="pointer fa fa-trash"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div><!--end content-wrap-container-->
    </form>
    <delivery-schedule
      class="hidden"
      :typology_id="form.id"
      :house_quantities="house_quantities"
    />
  </div>
</template>

<script>
import Tabs from "../../components/Project/Tabs.vue";
import DeliverySchedule from "./Components/DeliverySchedule.vue";
import {
  getallBottomCabinets,
  getAllDoors,
  getAllExteriorEndings,
  getAllExteriorPaints,
  getAllFloors,
  getAllInteriorEndings,
  getAllInteriorPaints,
  getAllIrons,
  getAllWindows,
  getAllUpstairsCabinets,
  getAllTerrain,
  getAllKitchens,
  getAllTypologies,
  getAllHousingTypes,
  getAllConstructionSystems,
} from "../../services/DropdownListsAPI.js";
import { getProjectInformation } from "../../services/projectAPI";
import {
  createTypology,
  updateTypology,
  gettypologyInformation,
  getEditTypologyInformation,
} from "../../services/TypologyAPI";
import Swal from "sweetalert2";

export default {
  components: { Tabs, DeliverySchedule },
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      showResume: true,
      editMode: 0,
      loading: false,
      documentTypes: [],
      housing: {},
      housing_typologies_created: [],
      house_quantities: [],
      project_form: {
        id: null,
        name: null,
        address: null,
        georeference: null,
        project_manager: null,
        identity_card: null,
        phone_number: null,
        cell_phone_number: null,
        email: null,
      },
      form: {
        id: null,
        project_id: null,
        typology_id: null,
        house_type_id: null,
        quantity_per_typology: null,
        construction_system_id: null,
        other: null,
        housing_m2: null,
        sale_price_m2: null,
        number_rooms: null,
        floor_id: null,
        floors_other: null,
        door_id: null,
        doors_other: null,
        window_id: null,
        windows_other: null,
        kitchen_id: null,
        kitchen_other: null,
        interior_paint_id: null,
        interior_painting_other: null,
        interior_ending_id: null,
        inside_finish_other: null,
        exterior_ending_id: null,
        outside_finish_other: null,
        iron_id: null,
        bottom_cabinet_id: null,
        upstairs_cabinet_id: null,
      },
      projectInfo: {
        rnc: "",
        business_name: "",
        dgii_status: "",
        person_type_id: "",
        address: "",
        state: "",
        phone1: "",
        phone2: "",
        legal_representation: "",
        position: "",
        email: "",
        office_phone: "",
        cellphone: "",
        total_assets: 1,
        total_liabilities: 2,
        current_assets: 3,
        current_liabilities: 4,
        total_passive: 5,
        heritage: 6,
      },
      BottomCabinets: [],
      InteriorPaints: [],
      Doors: [],
      ExteriorEndings: [],
      ExteriorPaints: [],
      AllFloors: [],
      InteriorEndings: [],
      Irons: [],
      Windows: [],
      UpstairsCabinets: [],
      Terrain: [],
      Kitchens: [],
      Typologies: [],
      HousingTypes: [],
      ConstructionSystems: [],
    };
  },
  mounted() {
    this.fillDropDowns();
    this.getProjectData();
    this.documentTypes = [
      "Registro Mercantil",
      "Estatutos de la empresa",
      "Acta de Asamblea(última)",
      "Certificacion RNC",
      "Certificacion TSS",
      "Presentacion de la empresa(Curriculum)",
      "Tesoreria de la seguridad social(TSS)",
      "Cédula del Representante Legal",
    ];

    gettypologyInformation(this.projectId).then((data) => {
      this.housing_typologies_created = data.project_typology;
    });
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    getProjectData() {
      if (this.projectId != null) {
        getProjectInformation(this.projectId)
          .then((data) => {
            this.project_form = data.project;
          })
          .catch(() => {
            Swal.fire({
              title: "¡Error al intentar obtener la información!",
              text: "",
              icon: "error",
              confirmButtonText: "Volver",
            });
          });
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.project_id = this.project_form.id;
      this.showResume = false;

      if (!this.editMode) {
        createTypology(this.form)
          .then((data) => {
            this.resetForm();

            this.$router.go();

            Swal.fire({
              title: "¡Proceso exitoso!",
              text: data,
              icon: "success",
              confirmButtonText: "Listo",
            });
          })
          .catch(() => {
            Swal.fire({
              title: "¡Error al intentar enviar el formulario!",
              text: "Favor verifique si todos los campos han sido completados",
              icon: "error",
              confirmButtonText: "Volver",
            });
          });
      } else {
        updateTypology(this.form)
          .then((data) => {
            Swal.fire({
              title: "¡Actualización exitosa!",
              text: data.message,
              icon: "success",
              confirmButtonText: "Listo",
            });
          })
          .catch(() => {
            Swal.fire({
              title: "¡Error al intentar enviar el formulario!",
              text: "Favor verifique si todos los campos han sido completados",
              icon: "error",
              confirmButtonText: "Volver",
            });
          });
      }
    },
    resetForm() {
      this.form = {
        project_id: null,
        typology_id: null,
        house_type_id: null,
        quantity_per_typology: null,
        construction_system_id: null,
        other: null,
        housing_m2: null,
        sale_price_m2: null,
        number_rooms: null,
        floor_id: null,
        floors_other: null,
        door_id: null,
        doors_other: null,
        window_id: null,
        windows_other: null,
        kitchen_id: null,
        kitchen_other: null,
        interior_paint_id: null,
        interior_painting_other: null,
        interior_ending_id: null,
        inside_finish_other: null,
        exterior_ending_id: null,
        outside_finish_other: null,
        iron_id: null,
        bottom_cabinet_id: null,
        upstairs_cabinet_id: null,
      };
    },
    async fillDropDowns() {
      this.BottomCabinets = await getallBottomCabinets();
      this.Doors = await getAllDoors();
      this.ExteriorEndings = await getAllExteriorEndings();
      this.ExteriorPaints = await getAllExteriorPaints();
      this.AllFloors = await getAllFloors();
      this.InteriorEndings = await getAllInteriorEndings();
      this.InteriorPaints = await getAllInteriorPaints();
      this.Irons = await getAllIrons();
      this.Windows = await getAllWindows();
      this.UpstairsCabinets = await getAllUpstairsCabinets();
      this.Terrain = await getAllTerrain();
      this.Kitchens = await getAllKitchens();
      this.Typologies = await getAllTypologies();
      this.HousingTypes = await getAllHousingTypes();
      this.ConstructionSystems = await getAllConstructionSystems();
    },
    hideEdit() {
      this.showResume = 1;
    },
    getHouseTypologyById(house_typology_id) {
      getEditTypologyInformation(house_typology_id)
        .then((data) => {
          this.showResume = 0;

          this.form = {
            id: house_typology_id,
            project_id: data.data.project_id,
            typology_id: data.data.typology_id,
            house_type_id: data.data.house_type_id,
            quantity_per_typology: data.data.quantity_per_typology,
            construction_system_id: data.data.construction_system_id,
            other: data.data.other,
            housing_m2: data.data.housing_m2,
            sale_price_m2: data.data.sale_price_m2,
            number_rooms: data.data.number_rooms,
            floor_id: data.endings.floor_id,
            floors_other: data.endings.floors_other,
            door_id: data.endings.door_id,
            doors_other: data.endings.doors_other,
            window_id: data.endings.window_id,
            windows_other: data.endings.windows_other,
            kitchen_id: data.endings.kitchen_id,
            kitchen_other: data.endings.kitchen_other,
            interior_paint_id: data.endings.interior_paint_id,
            interior_painting_other: data.endings.interior_painting_other,
            exterior_paint_id: data.endings.exterior_paint_id,
            exterior_painting_other: data.endings.exterior_painting_other,
            interior_ending_id: data.endings.interior_ending_id,
            inside_finish_other: data.endings.inside_finish_other,
            exterior_ending_id: data.endings.exterior_ending_id,
            outside_finish_other: data.endings.outside_finish_other,
            iron_id: data.endings.iron_id,
            bottom_cabinet_id: data.endings.bottom_cabinet_id,
            upstairs_cabinet_id: data.endings.upstairs_cabinet_id,
          };

          console.log("data.house_quantities", data.house_quantities);

          this.house_quantities = data.house_quantities;

          this.editMode = 1;
        })
        .catch(() => {
          Swal.fire({
            title: "¡Error al intentar cargar la información!",
            text: "Favor intente de nuevo",
            icon: "error",
            confirmButtonText: "Volver",
          });
        });
    },
    getHousesQuantities() {
      let total = 0;

      setTimeout(() => {
        let quantity_fields = document.querySelectorAll(".quantity_field");

        for (let i = 0; i <= quantity_fields.length; i++) {
          if (quantity_fields[i] !== undefined) {
            let year_id = quantity_fields[i].getAttribute("data-year_id");

            for (let hq in this.house_quantities) {
              if (
                parseInt(this.house_quantities[hq].year_id) ===
                parseInt(year_id)
              ) {
                quantity_fields[i].value = this.house_quantities[hq].quantity;
                total = total + this.house_quantities[hq].quantity;
              }
            }
          }
        }
        document.getElementById("total_houses").value = total;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
form .form-group {
  text-align: start;
}
table tr th,
table tr td {
  text-align: start;
}

table tr td:last-child {
  text-align: right !important;
}
</style>
