import { api } from "../core/api";

const createTypology = async (data) => {
  const response = await api.post(
    `/api/project/typology-house/endings/store`,
    data
  );
  return response.data;
};

const updateTypology = async (data) => {
  const response = await api.post(
    `/api/project/typology-house/endings/update`,
    data
  );
  return response.data;
};

const gettypologyInformation = async (projectId) => {
  const response = await api.get(
    `/api/project/typology-house/endings/get/${projectId}`
  );
  return response.data;
};

const getEditTypologyInformation = async (house_typology_id) => {
  const response = await api.get(
    `/api/project/typology-house/endings/edit/${house_typology_id}`
  );
  return response.data;
}

// DELIVERY SCHEDULE
const getDeliveryScheduleYears = async () => {
  const response = await api.get(
      'api/project/typology-house/delivery-schedule/years/all'
  );
  return response.data;
}

const getHouseQuantitiesSavedBatch = async (house_typology_id) => {
  const response = await api.get(
      `api/project/typology-house/delivery-schedule/saved/all/${house_typology_id}`
  );
  return response.data;
}

const createDeliverySchedule = async (data) => {
  const response = await api.post(
      'api/project/typology-house/delivery-schedule',
      data
  );
  return response.data;
}

export {
  createTypology, updateTypology, gettypologyInformation, getEditTypologyInformation, getDeliveryScheduleYears,
  createDeliverySchedule, getHouseQuantitiesSavedBatch,
};
